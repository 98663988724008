import { PlusCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Avatar, Col, Dropdown, Row, Typography } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import BackButton from 'components/buttons/BackButton';
import GGButton from 'components/buttons/GGButton';
import CaregiverProfileModal from 'components/modals/CaregiverProfileModal';
import AppColors from 'config/AppColors';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import ApiCaregiversManager from 'services/api/ApiCaregiversManager';
import ApiDoctorConversationsManager, { StatusChangeRequestType } from 'services/api/ApiDoctorConversationsManager';
import { conversationActions } from 'store/conversations';
import { selectCurrentConversationId } from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import logoAsset from '../../assets/logo.png';

const { Title } = Typography;

export type RecipientHeaderProps = {
  name?: string;
  avatar?: string | React.ReactNode;
  caregiverId?: string;
  backButton?: boolean;
  onBackButtonClick?: () => void;
};

const useStyles = createUseStyles({
  container: {
    marginTop: 15,
    paddingLeft: 13,
    paddingRight: 13,
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
  },
  columnBackButton: {
    marginRight: 10,
  },
  columnAvatar: {
    marginRight: 5,
  },
  name: {
    margin: 0,
  },
  caregiverList: {},
  caregiverItem: {
    backgroundColor: AppColors.backgroundGrey,
    borderRadius: 5,
    marginTop: 5,
    cursor: 'pointer',
  },
});

const RecipientHeader: React.FC<RecipientHeaderProps> = ({
  name = undefined,
  avatar = undefined,
  caregiverId = undefined,
  backButton = false,
  onBackButtonClick = undefined,
}) => {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const [api, context] = useNotification();
  const dispatch = useDispatch();

  // Selectors
  const currentConversationId = useSelector(selectCurrentConversationId);
  const currentUser = useSelector(selectCurrentUser);

  // State
  const [openProfileModal, setOpenProfileModal] = useState(false);

  function onBackButtonClicked() {
    if (onBackButtonClick) {
      onBackButtonClick();
    }
  }

  const {
    loading: loadingGetCaregiverGroupsList,
    run: getCaregiverGroupsList,
    data,
  } = useRequest(() => ApiCaregiversManager.getAvailableCaregiverGroupsList(), {
    manual: true,
    onError: () => {
      api.error({
        message: t('caregivers.list.errors.fetch'),
      });
    },
  });

  const { loading: loadingRequestOwnerChange, run: requestOwnerChange } = useRequest(
    caregiverGroupId =>
      ApiDoctorConversationsManager.requestOwnerChange({ caregiverGroupId, conversationId: currentConversationId }),
    {
      manual: true,
      onSuccess: () => {
        api.success({
          message: t('caregivers.actions.requestOwnerChange.success'),
        });
        dispatch(conversationActions.setCurrentConversation(undefined));
        onBackButtonClicked();
      },
      onError: () => {
        api.error({
          message: t('caregivers.actions.requestOwnerChange.error'),
        });
      },
    },
  );

  const { loading: loadingStatusChange, run: requestStatusChange } = useRequest(
    (newStatus: StatusChangeRequestType) =>
      ApiDoctorConversationsManager.requestConversationStatusChange(currentConversationId, newStatus),
    {
      manual: true,
      onSuccess: (coucou, params) => {
        api.success({
          message: t('caregivers.actions.statusChange.success'),
        });
        dispatch(
          conversationActions.setConversationStatus({
            conversationId: currentConversationId,
            newStatus: params[0] === 'CLOSED' ? 'closed' : 'onHold',
          }),
        );
        dispatch(conversationActions.setCurrentConversation(undefined));
        onBackButtonClicked();
      },
      onError: () => {
        api.error({
          message: t('caregivers.actions.statusChange.error'),
        });
      },
    },
  );

  function transfer() {
    getCaregiverGroupsList();
  }

  function selectCaregiverGroup(caregiverGroupId: string) {
    requestOwnerChange(caregiverGroupId);
  }

  const menuProps = {
    items: [
      {
        key: 'put_on_hold',
        label: t('chats.actions.putOnHold'),
      },
      {
        key: 'close',
        label: t('chats.actions.close'),
      },
      {
        key: 'assign',
        label: t('chats.actions.transferTo'),
        children: (data || []).map(d => ({
          key: `assign.${d.id}`,
          label: d.name,
        })),
      },
    ],
    onClick: (e: any) => {
      if (e.key === 'close') requestStatusChange('CLOSED');
      else if (e.key === 'put_on_hold') requestStatusChange('ON_HOLD');
      else if (e.key.startsWith('assign.')) selectCaregiverGroup(e.key.replace('assign.', ''));
    },
  };
  const styleIsClickable = {
    cursor: 'pointer',
  };

  return (
    <Row className={styles.container}>
      {context}
      {openProfileModal && caregiverId && (
        <CaregiverProfileModal caregiverId={caregiverId} open onClose={() => setOpenProfileModal(false)} />
      )}
      {backButton && (
        <Col className={styles.columnBackButton}>
          <BackButton onClick={() => onBackButtonClicked()} />
        </Col>
      )}
      {avatar && (
        <Col
          className={styles.columnAvatar}
          onClick={() => setOpenProfileModal(true)}
          style={caregiverId ? styleIsClickable : undefined}
        >
          {typeof avatar === 'string' && avatar.startsWith('http') ? (
            <Avatar src={avatar} />
          ) : (
            <Avatar src={logoAsset} />
          )}
        </Col>
      )}
      <Col onClick={() => setOpenProfileModal(true)} style={caregiverId ? styleIsClickable : undefined}>
        <Title level={3} className={styles.name}>
          {name}
        </Title>
      </Col>
      {currentUser?.is_caregiver && currentConversationId && (
        <Col
          flex="auto"
          style={{
            justifyContent: 'end',
            display: 'flex',
            alignItems: 'start',
          }}
        >
          <Dropdown menu={menuProps}>
            <GGButton
              type="default"
              icon={<PlusCircleOutlined />}
              onClick={() => transfer()}
              disabled={loadingGetCaregiverGroupsList || loadingRequestOwnerChange || loadingStatusChange}
              loading={loadingGetCaregiverGroupsList || loadingRequestOwnerChange || loadingStatusChange}
            >
              {t('chats.actions.button')}
            </GGButton>
          </Dropdown>
        </Col>
      )}
    </Row>
  );
};
export default RecipientHeader;
