import { App } from '@capacitor/app';
import { useLocalStorageState, useRequest } from 'ahooks';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiManager } from 'services/api/ApiManager';
import ApiTokenManager, {
  ApiTokenGetTokenParams,
  ApiTokenRefreshAccessTokenParams,
} from 'services/api/ApiTokenManager';
import ApiUsersManager from 'services/api/ApiUsersManager';
import { userActions } from 'store/users';
import { selectCurrentUser } from 'store/users/selectors';
import useWindowFocus from 'use-window-focus';

import JwtUtils from 'utils/JwtUtils';

export default function useAuthGuard() {
  // Hooks
  const dispatch = useDispatch();
  const [localStorageAccessToken, setLocalStorageAccessToken] = useLocalStorageState<string | undefined>(
    'gynger-access-token',
  );
  const [localStorageRefreshToken, setLocalStorageRefreshToken] = useLocalStorageState<string | undefined>(
    'gynger-refresh-token',
  );

  const refreshTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const appStateResumed = useRef<boolean>(true);

  // Selector
  const currentUser = useSelector(selectCurrentUser);

  const windowFocused = useWindowFocus();
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  App.addListener('appStateChange', ({ isActive }) => {
    appStateResumed.current = isActive;
  });

  const {
    loading: loadingCurrentUser,
    error: errorCurrentUser,
    run: getCurrentUser,
  } = useRequest(() => ApiUsersManager.getUsersMe(), {
    manual: true,
    onSuccess: result => {
      dispatch(userActions.setCurrentUser(result));

      return result;
    },
  });

  const setTokensInLocalStorageAndMemory = (params: { accessToken: string; refreshToken?: string }) => {
    ApiManager.setTokens({ accessToken: params.accessToken, refreshToken: params.refreshToken });

    setLocalStorageAccessToken(params.accessToken);
    setLocalStorageRefreshToken(params.refreshToken);
  };

  const {
    loading: loadingRefreshAccessToken,
    error: errorRefreshAccessToken,
    run: refreshAccessToken,
  } = useRequest((params: ApiTokenRefreshAccessTokenParams) => ApiTokenManager.refreshAccessToken(params), {
    manual: true,
    onSuccess: (result, params) => {
      setTokensInLocalStorageAndMemory({ accessToken: result.access, refreshToken: result.refresh });

      getCurrentUser();

      const decodedAccessToken = JwtUtils.parseJwt(result.access);
      if (decodedAccessToken) {
        const expiresIn = JwtUtils.expiresIn(decodedAccessToken);
        clearTimeout(refreshTimerRef.current);
        refreshTimerRef.current = setTimeout(() => {
          refreshAccessToken({ refresh: params[0].refresh });
        }, expiresIn);
      }

      return result;
    },
  });

  const refreshAccessTokenWhenExpired = (accessToken: string, refreshToken: string) => {
    const decodedAccessToken = JwtUtils.parseJwt(accessToken);

    if (decodedAccessToken) {
      const expiresIn = JwtUtils.expiresIn(decodedAccessToken);
      clearTimeout(refreshTimerRef.current);
      refreshTimerRef.current = setTimeout(() => {
        refreshAccessToken({ refresh: refreshToken });
      }, expiresIn);
    }
  };

  const {
    loading: loadingTokens,
    error: errorTokens,
    run: getAccessToken,
  } = useRequest((params: ApiTokenGetTokenParams) => ApiTokenManager.getAccessToken(params), {
    manual: true,
    onSuccess: result => {
      setTokensInLocalStorageAndMemory({ accessToken: result.access, refreshToken: result.refresh });

      refreshAccessTokenWhenExpired(result.access, result.refresh);

      getCurrentUser();

      return result;
    },
  });

  const isLoading = loadingTokens || loadingRefreshAccessToken || loadingCurrentUser;
  const error = errorTokens || errorRefreshAccessToken || errorCurrentUser;
  const accessToken = ApiManager.getAccessToken();
  // The API is callable when there is a valid access token and a current user
  const canCallApi = !!(accessToken && currentUser);

  const validateOrRefreshTokens = (xxAccessToken?: string, xxRefreshToken?: string) => {
    // First, check there are the tokens
    if (xxAccessToken && xxRefreshToken) {
      // If so, decode them
      const decodedAccessToken = JwtUtils.parseJwt(xxAccessToken);
      const decodedRefreshToken = JwtUtils.parseJwt(xxRefreshToken);

      if (decodedAccessToken && decodedRefreshToken && !JwtUtils.isExpired(decodedAccessToken)) {
        if (!currentUser) getCurrentUser();
        return true;
      }

      // If the access token has expired, renew it
      refreshAccessToken({ refresh: xxRefreshToken });
      return true;
    }

    return false;
  };

  const getValidTokenOrRefresh = () => {
    if (!isLoading) {
      // First, try to get access and refresh token from the local storage
      if (localStorageAccessToken && localStorageRefreshToken) {
        // load the local storage tokens in memory
        ApiManager.setTokens({ accessToken: localStorageAccessToken, refreshToken: localStorageRefreshToken });

        if (validateOrRefreshTokens(localStorageAccessToken, localStorageRefreshToken)) {
          refreshAccessTokenWhenExpired(localStorageAccessToken, localStorageRefreshToken);
        }
      } else {
        const { accessToken: access, refreshToken } = ApiManager.getTokens();
        if (validateOrRefreshTokens(access, refreshToken) && access && refreshToken) {
          refreshAccessTokenWhenExpired(access, refreshToken);
        }
      }
    }
  };

  // On mount, if the access token exist but expired, or no token, then refresh token silently, and get user details from API
  useEffect(() => {
    getValidTokenOrRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocused, appStateResumed]);

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(refreshTimerRef.current);
  }, []);

  return {
    canCallApi,
    isLoading,
    error,
    accessToken,
    getAccessToken,
  };
}
