import { ApiManager } from './ApiManager';

export type ApiTokenGetTokenParams = {
  email: string;
  password: string;
};
export type ApiTokenGetTokenResult = {
  refresh: string;
  access: string;
};
export type ApiTokenRefreshAccessTokenParams = {
  refresh: string;
};
export type ApiTokenRefreshAccessTokenResult = {
  refresh: string;
  access: string;
};

export default class ApiTokenManager {
  static async getAccessToken(params: ApiTokenGetTokenParams): Promise<ApiTokenGetTokenResult> {
    const apiManager = ApiManager.getInstance();

    const response = await apiManager.post(`/api/auth/jwt/create/`, params);

    return response.data as ApiTokenGetTokenResult;
  }

  static async refreshAccessToken(params: ApiTokenRefreshAccessTokenParams): Promise<ApiTokenRefreshAccessTokenResult> {
    const accessToken = ApiManager.getAccessToken();
    const apiManager = ApiManager.getInstance(accessToken);

    const response = await apiManager.post(`/api/auth/jwt/refresh/`, params);

    return response.data as ApiTokenRefreshAccessTokenResult;
  }
}
