import { t } from '../locales/i18nManager';

// eslint-disable-next-line import/prefer-default-export
export const routes = {
  login: {
    key: 'login',
    label: t('menu.login'),
    route: '/login',
  },
  register: {
    key: 'register',
    label: t('menu.register'),
    route: '/register',
  },
  registerAskIfCode: {
    key: 'registerAskIfCode',
    route: '/register-ask-if-code',
  },
  registerNoCode: {
    key: 'registerNoCode',
    route: '/register-no-code',
  },
  onboarding: {
    key: 'onboarding',
    route: '/onboarding',
  },
  passwordResetSendEmail: {
    key: 'passwordResetSendEmail',
    label: t('menu.passwordReset'),
    route: '/password-reset',
  },
  passwordReset: {
    key: 'passwordReset',
    label: t('menu.passwordReset'),
    route: '/password/reset/:uid/:token',
  },
  home: {
    key: 'home',
    label: t('menu.home'),
    route: '/',
    routeReload: '/?reload=true',
    routeNoReload: '/?reload=false',
  },
  conversation: {
    key: 'conversation',
    label: t('menu.conversation'),
    route: '/conversations/:conversationId',
    routeReload: '/conversations/:conversationId?reload=true',
  },
  newChat: {
    key: 'newChat',
    label: t('menu.newChat'),
    route: '/new-conversation',
  },
  newChatCheckup: {
    key: 'newChatCheckup',
    label: t('menu.newChat'),
    route: '/new-conversation?conversationType=checkup',
  },
  info: {
    key: 'info',
    label: t('menu.info'),
    route: '/info',
  },
  caregiversDirectory: {
    key: 'caregiversDirectory',
    label: t('menu.caregiversDirectory'),
    route: '/caregivers',
  },
  settings: {
    key: 'settings',
    label: t('menu.settings'),
    route: '/',
  },
  logout: {
    key: 'logout',
    label: t('menu.logout'),
    route: '/logout',
  },
  notFound: {
    key: 'notFound',
    label: t('menu.notFound'),
    route: '*',
  },
  unauthorized: {
    key: 'unauthorized',
    label: t('menu.unauthorized'),
    route: '*',
  },
};
