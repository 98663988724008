/* eslint-disable no-nested-ternary */
import { useRequest } from 'ahooks';
import useNotification from 'antd/es/notification/useNotification';
import MessageInput from 'components/inputs/MessageInput';
import SkeletonMessages from 'components/skeleton/SkeletonMessages';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'services/RouteService';
import ApiDoctorConversationsManager, {
  ApiDoctorConversationsPostMessageToDoctorConversationResult,
} from 'services/api/ApiDoctorConversationsManager';
import ApiPatientConversationsManager, {
  ApiPatientConversationsPostMessageToPatientConversationsResult,
} from 'services/api/ApiPatientConversationsManager';
import { ConversationMessage } from 'services/api/models/ConversationDTO';
import { conversationActions } from 'store/conversations';
import {
  selectCurrentConversationId,
  selectCurrentConversationMessages,
  selectCurrentConversationSummary,
  selectWebsocketConnected,
} from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import defaultAvatarUrl from '../../assets/experts-man-dark.png';
import ConversationButtons from './ConversationButtons';
import MessagesList from './MessagesList';
import RecipientHeader from './RecipientHeader';

export default function ConversationPanel() {
  // Hooks
  const dispatch = useDispatch();
  const [api, context] = useNotification();
  const { t } = useTranslation();
  const { isSmallScreen } = useIsSmallScreen();
  const isWebsocketConnected = useSelector(selectWebsocketConnected);
  const navigate = useNavigate();

  // Selectors
  const currentConversationId = useSelector(selectCurrentConversationId);
  const currentUser = useSelector(selectCurrentUser);
  const currentConversation = useSelector(selectCurrentConversationMessages, shallowEqual);
  const currentConversationMessages = useSelector(selectCurrentConversationMessages, shallowEqual);
  const currentConversationSummary = useSelector(selectCurrentConversationSummary);

  // TODO this is very hard to read
  const isCurrentUserIsOwner =
    currentConversation &&
    currentConversationSummary?.conversation &&
    currentConversationSummary?.conversation.current_owner &&
    currentConversationSummary.conversation.current_owner.id === currentUser?.id;

  // Show the self assign button if:
  // - there is no owner yet
  // - or there is an owner, but this is not the current user/caregiver
  const displaySelfAssign =
    currentUser?.is_caregiver &&
    (!currentConversationSummary?.conversation.current_owner ||
      !isCurrentUserIsOwner ||
      currentConversationSummary.type === 'unassigned');

  const { run, loading } = useRequest(
    (conversationId: string) => {
      if (currentUser?.is_caregiver) {
        return ApiDoctorConversationsManager.getDoctorConversationMessageList({ conversationId });
      }
      return ApiPatientConversationsManager.getPatientConversationsMessageList({ conversationId });
    },
    {
      manual: true,
      onSuccess: result => {
        dispatch(conversationActions.setConversationMessages(result));
      },
      onError: () => {
        api.error({
          message: t('chats.list.errors.fetch'),
        });
      },
    },
  );

  useEffect(() => {
    // On mount get conversation for a patient or a caregiver
    if (currentConversationId) {
      run(currentConversationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConversationId, isWebsocketConnected]);

  function sendMessage(messageText: string) {
    if (currentUser?.is_caregiver) {
      return ApiDoctorConversationsManager.postMessageToDoctorConversation({
        conversationId: currentConversationId,
        messageText,
      });
    }

    return ApiPatientConversationsManager.postMessageToPatientConversations({
      conversationId: currentConversationId,
      messageText,
    });
  }

  function onMessageSent(
    result:
      | ApiPatientConversationsPostMessageToPatientConversationsResult
      | ApiDoctorConversationsPostMessageToDoctorConversationResult,
  ) {
    if (currentUser) {
      dispatch(
        conversationActions.addMessageToConversation({
          conversationId: currentConversationId,
          messageText: result.messageText,
          sender: currentUser,
        }),
      );
    }
  }

  function onBackButtonClick() {
    if (isSmallScreen) {
      navigate(routes.home.routeNoReload, { replace: true });

      // On small screen, reset the current selected conversation to display the "previous" screen
      dispatch(conversationActions.resetCurrentConversation());
    } else {
      navigate(routes.home.routeReload, { replace: true });
    }
  }

  // Add an extra message (saying the request will be handled) if
  // the user is not a caregiver
  // and the conversation has not been answered by a caregiver yet
  const extraMessage =
    !currentUser?.is_caregiver &&
    currentConversationMessages &&
    currentConversationMessages.length > 0 &&
    !currentConversationMessages.find(ms => ms.sender.is_caregiver)
      ? {
          id: 'extra',
          date: new Date().toISOString(),
          status: 'sent',
          text: t('chats.noCaregiver.extraConversationMessage.forPatient'),
          type: 'text' as const,
          sender: {
            id: t('chats.new.introMessageSenderId'),
            display_name: t('chats.new.introMessageSenderName'),
            photo_url: defaultAvatarUrl,
            is_caregiver: false,
          },
        }
      : [];

  function messageStatus(message: ConversationMessage) {
    if (currentUser?.is_caregiver) {
      const readStatus = currentConversationSummary?.conversation.read_statuses?.filter(
        status => status.reader === currentConversationSummary?.conversation.patient.id,
      );
      if (readStatus?.length && readStatus[0].last_read_datetime >= message.sent_at) {
        return 'read';
      }
    }
    return 'sent';
  }

  const messagesForConversations = (currentConversationMessages || []).map((message, index) => ({
    id: index.toString(),
    date: message.sent_at,
    status: messageStatus(message),
    text: message.message_text,
    type: 'text' as const,
    sender: {
      id: message.sender.id,
      display_name: message.sender.display_name,
      photo_url: message.sender.photo_url,
      is_caregiver: message.sender.is_caregiver,
    },
  }));

  const firstMessage = messagesForConversations.length > 0 ? [messagesForConversations[0]] : [];
  const restOfMessages = messagesForConversations.length > 1 ? messagesForConversations.slice(1) : [];
  const messages = firstMessage.concat(extraMessage).concat(restOfMessages);

  // If the current user is a caregiver, display the patient avatar or a failover avatar. Otherwise, display the caregiver's avatar
  const recipientAvatar = currentUser?.is_caregiver
    ? currentConversationSummary?.conversation.patient.photo_url ||
      currentConversationSummary?.conversation.patient.display_name[0] ||
      t('chats.noCaregiver.avatar')
    : currentConversationSummary?.conversation.current_owner?.photo_url ||
      currentConversationSummary?.conversation.current_owner?.display_name[0] ||
      t('chats.noCaregiver.avatar');

  // If the current user is a caregiver, display the patient name or a failover name. Otherwise, display the caregiver's name
  const recipientName = currentUser?.is_caregiver
    ? currentConversationSummary?.conversation.patient.display_name || t('chats.noCaregiver.name.forCaregiver')
    : currentConversationSummary?.conversation.current_owner?.display_name || t('chats.noCaregiver.name.forPatient');

  const recipientId = currentUser?.is_caregiver
    ? undefined
    : currentConversationSummary?.conversation.current_owner?.id;

  // Loading the conversation for the very first time
  const firstTimeLoading = loading && currentConversationMessages.length === 0;

  return (
    <>
      {context}
      <>
        <RecipientHeader
          backButton={isSmallScreen}
          avatar={currentConversationId ? recipientAvatar : null}
          name={currentConversationId ? recipientName : t('chats.list.noChatsTitle')}
          caregiverId={currentConversationId ? recipientId : undefined}
          onBackButtonClick={() => onBackButtonClick()}
        />
        {firstTimeLoading ? <SkeletonMessages numberOfTupleMessages={3} /> : <MessagesList messages={messages} />}

        {displaySelfAssign && <ConversationButtons />}
        {!displaySelfAssign && (
          <MessageInput
            disabled={!currentConversationId}
            sendMessage={message => sendMessage(message)}
            onMessageSent={result => onMessageSent(result)}
          />
        )}
      </>
    </>
  );
}
