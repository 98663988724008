/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable prettier/prettier */
import { useRequest } from 'ahooks';
import { Col, Row } from 'antd';
import useNotification from 'antd/es/notification/useNotification';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import ApiDoctorConversationsManager from 'services/api/ApiDoctorConversationsManager';
import ApiPatientConversationsManager from 'services/api/ApiPatientConversationsManager';
import { conversationActions } from 'store/conversations';
import { selectCurrentConversationId, selectWebsocketConnected } from 'store/conversations/selectors';
import { selectCurrentUser } from 'store/users/selectors';
import ChatsPanel from '../components/chats/ChatsPanel';
import ConversationPanel from '../components/chats/ConversationPanel';

const useStyles = createUseStyles({
  container: {
    height: '100%',
  },
  chatsContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRightColor: 'rgba(0, 0, 0, 0.1)',
    borderRightStyle: 'solid',
    borderRightWidth: '0.555556px',
  },
  conversationsContainer: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
  },
});

export default function Home() {
  // Hooks
  const styles = useStyles();
  const dispatch = useDispatch();
  const [api, context] = useNotification();
  const { t } = useTranslation();
  const urlParams = useParams();
  const isWebsocketConnected = useSelector(selectWebsocketConnected);
  const conversationId = useSelector(selectCurrentConversationId);
  const { isSmallScreen } = useIsSmallScreen();

  // Selector
  const currentUser = useSelector(selectCurrentUser);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryStringParams, setQueryStringParams] = useSearchParams();

  function markConversationAsRead(conversationIdToMark: string) {
    // Mark the messages as read
    dispatch(conversationActions.resetUnreadMessages({ conversationId: conversationIdToMark }));
    if (currentUser?.is_caregiver) {
      ApiDoctorConversationsManager.markConversationAsRead(conversationIdToMark);
    } else {
      ApiPatientConversationsManager.markConversationAsRead(conversationIdToMark);
    }
  }

  const { run, refresh } = useRequest(
    () => {
      if (currentUser?.is_caregiver) {
        return ApiDoctorConversationsManager.getDoctorConversationsList();
      }
      return ApiPatientConversationsManager.getPatientConversationsList();
    },
    {
      manual: true,
      onBefore: () => dispatch(conversationActions.setConversationLoadingStatus('LOADING')),
      onSuccess: result => {
        dispatch(conversationActions.setConversationLoadingStatus('SUCCESS'));

        dispatch(conversationActions.setConversations({ conversations: result, currentUserId: currentUser!.id }));

        if (!isSmallScreen && result.length > 0 && !currentUser?.is_caregiver) {
          if (!conversationId) {
            const resultConversationId = result[0].id;
            dispatch(conversationActions.setCurrentConversation(resultConversationId));
            markConversationAsRead(resultConversationId);
          }
        }
      },
      onError: () => {
        dispatch(conversationActions.setConversationLoadingStatus('ERROR'));

        api.error({
          message: t('chats.list.errors.fetch'),
        });
      },
    },
  );

  useEffect(() => {
    if (isWebsocketConnected) {
      // reload the history on websocket connection
      refresh();
    }
  }, [refresh, isWebsocketConnected]);

  useEffect(() => {
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Refresh conversation list if query string param 'reload' is present
    if (queryStringParams.has('reload') && queryStringParams.get('reload') === 'true') {
      run();
    }

    // Set the current conversation if the conversation param is in URL
    if (urlParams.conversationId) {
      dispatch(conversationActions.setCurrentConversation(urlParams.conversationId.toString()));

      markConversationAsRead(urlParams.conversationId.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStringParams, urlParams]);

  const currentConversationSelected = conversationId || urlParams.conversationId;

  const renderChatsPanel = () => (
    <Col className={styles.chatsContainer} xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 6 }}>
      <ChatsPanel />
    </Col>
  );

  const renderConversationsPanel = () => (
    <Col className={styles.conversationsContainer} xs={{ span: 24 }} sm={{ span: 16 }} lg={{ span: 18 }}>
      <ConversationPanel />
    </Col>
  );

  const renderScreen = () => {
    if (isSmallScreen) {
      return currentConversationSelected ? renderConversationsPanel() : renderChatsPanel();
    }

    return (
      <>
        {renderChatsPanel()}
        {renderConversationsPanel()}
      </>
    );
  };

  return (
    <Row className={styles.container}>
      {context}
      {renderScreen()}
    </Row>
  );
}
