import { Col, Row } from 'antd';
import MessagesList from 'components/chats/MessagesList';
import RecipientHeader from 'components/chats/RecipientHeader';
import MessageInput from 'components/inputs/MessageInput';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'services/RouteService';
import ApiPatientConversationsManager, {
  ApiPatientConversationsCreatePatientConversationsResult,
} from 'services/api/ApiPatientConversationsManager';
import defaultAvatarUrl from '../assets/experts-man-dark.png';

const useStyles = createUseStyles({
  container: {
    height: '100%',
  },
  conversationsContainer: {
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
  },
});

export default function NewConversation() {
  // Hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSmallScreen } = useIsSmallScreen();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [queryStringParams, setQueryStringParams] = useSearchParams();

  function sendMessage(messageText: string) {
    return ApiPatientConversationsManager.createPatientConversations({
      initialMessageText: messageText,
    });
  }

  function onBackButtonClick() {
    if (isSmallScreen) {
      navigate(routes.home.routeNoReload, { replace: true });
    } else {
      navigate(routes.home.routeReload, { replace: true });
    }
  }

  function onMessageSent(message: ApiPatientConversationsCreatePatientConversationsResult) {
    if (isSmallScreen) {
      // On small screen/mobile, do not go back to the conversation list
      // Display the conversation
      navigate(routes.conversation.routeReload.replace(':conversationId', message.id), { replace: true });
    } else {
      // On large screen, go back to the home page
      onBackButtonClick();
    }
  }

  const introMessageText =
    queryStringParams.has('conversationType') && queryStringParams.get('conversationType') === 'checkup'
      ? t('chats.new.introMessageHealthCheckUp')
      : t('chats.new.introMessageAskQuestion');

  const viewTitle =
    queryStringParams.has('conversationType') && queryStringParams.get('conversationType') === 'checkup'
      ? t('chats.new.titleHealthCheckUp')
      : t('chats.new.titleAskQuestion');

  const messages = [
    {
      id: '0',
      date: new Date().toISOString(),
      status: 'sent',
      text: introMessageText,
      type: 'text' as const,
      sender: {
        id: t('chats.new.introMessageSenderId'),
        display_name: t('chats.new.introMessageSenderName'),
        photo_url: defaultAvatarUrl,
        is_caregiver: false,
      },
    },
  ];

  return (
    <Row className={styles.container}>
      <Col className={styles.conversationsContainer} span={24}>
        <RecipientHeader backButton name={viewTitle} onBackButtonClick={() => onBackButtonClick()} />
        <MessagesList messages={messages} />
        <MessageInput sendMessage={message => sendMessage(message)} onMessageSent={result => onMessageSent(result)} />
      </Col>
    </Row>
  );
}
