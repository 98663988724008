import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IMessage } from 'react-chat-elements';
import { GGApiMessage, GGApiSender } from 'services/api/models/GGApiMessage';
import { UserDTO } from 'services/api/models/UserDTO';
import defaultCaregiverPhoto from '../assets/experts-man-dark.png';
import AppColors from '../config/AppColors';

// Load Dayjs plugin to use .fromNow()
dayjs.extend(relativeTime);

export type ChatMessage = IMessage & GGApiMessage;

export default class MessageUtil {
  static getChatMessageFromData(data: GGApiMessage[], currentUser?: UserDTO): ChatMessage[] {
    return data.map(item => {
      const isSenderMe = item.sender?.id === currentUser?.id;
      const title = isSenderMe ? null : item.sender?.display_name;
      const position = isSenderMe ? 'right' : 'left';
      const titleColor = isSenderMe ? AppColors.messages.me.title : AppColors.messages.recipient.title;

      const getSenderCaregiverPhoto = (sender?: GGApiSender) =>
        sender?.is_caregiver ? sender?.photo_url || defaultCaregiverPhoto : sender?.photo_url;

      return {
        ...(item as any),
        date: dayjs(item.date).fromNow(),
        dateString: dayjs(item.date).fromNow(),
        title,
        position,
        titleColor,
        // Do not display avatars if the current user is a caregiver
        // Otherwise (patient), display the caregiver photo or a failover
        avatar: currentUser?.is_caregiver ? null : getSenderCaregiverPhoto(item.sender),
        focus: false,
        forwarded: false,
        replyButton: false,
        removeButton: false,
        notch: false,
        retracted: false,
      } as ChatMessage;
    });
  }
}
