import { RootState } from 'types';
import { initialState } from '.';
import { ConversationType } from './types';

export const selectConversationLoadingStatusIsLoading = (state: RootState) => {
  return (state?.conversations?.conversationLoadingStatus || initialState.conversationLoadingStatus) === 'LOADING';
};

export const selectWebsocketConnected = (state: RootState) => {
  return state?.conversations?.websocketConnected || initialState.websocketConnected;
};

export const selectCurrentConversationId = (state: RootState) => {
  return state?.conversations?.currentConversationId || initialState.currentConversationId;
};

const selectConversationsByType = (state: RootState, type: ConversationType, isCaregiver?: boolean) => {
  if (isCaregiver) {
    return (state?.conversations?.conversationSummaries || initialState.conversationSummaries)
      .filter(conversation => conversation.type === type)
      .flatMap(c => c.conversation);
  }

  return (state?.conversations?.conversationSummaries || initialState.conversationSummaries).flatMap(
    c => c.conversation,
  );
};

export const selectConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'assigned', isCaregiver);
};

export const selectUnassignedConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'unassigned', isCaregiver);
};

export const selectMyRequestsConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'myRequest', isCaregiver);
};

export const selectWaitingForReassignmentConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'waitingForReassignment', isCaregiver);
};

export const selectOnHoldConversations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'onHold', isCaregiver);
};

export const selectClosedConvesrations = (state: RootState, isCaregiver?: boolean) => {
  return selectConversationsByType(state, 'closed', isCaregiver);
};

export const selectConversationMessages = (state: RootState, conversationId: string) => {
  return (state?.conversations?.messagesPerConversation || initialState.messagesPerConversation)[conversationId] || [];
};

export const selectCurrentConversationSummary = (state: RootState) => {
  const currentConversationId = selectCurrentConversationId(state);
  return (state?.conversations?.conversationSummaries || initialState.conversationSummaries).find(
    conversation => conversation.conversation.id === currentConversationId,
  );
};

export const selectCurrentConversationMessages = (state: RootState) => {
  const currentConversationId = selectCurrentConversationId(state);
  return selectConversationMessages(state, currentConversationId);
};

export const selectCurrentInputText = (state: RootState) => {
  const currentConversationId = selectCurrentConversationId(state);
  return state?.conversations?.conversationInputTexts[currentConversationId];
};
