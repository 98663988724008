/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/no-danger */
import { Avatar, Card, Divider, List, Row } from 'antd';
import Meta from 'antd/es/card/Meta';
import PageTopBar from 'components/navigation/PageTopBar';
import AppColors from 'config/AppColors';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import ScrollUtil from 'utils/ScrollUtil';

const data = [
  {
    title: 'Infertilité, tous concernés !',
    html: require('../blog/html/infertilite'),
    image: require('../blog/images/infertilite.jpg'),
  },
  {
    title: 'Comment évaluer la douleur ?',
    html: require('../blog/html/evaluer-douleur'),
    image: require('../blog/images/evaluer-douleur.jpg'),
  },
  {
    title: "C'est quoi l'adénomyose ?",
    html: require('../blog/html/adenomyose'),
    image: require('../blog/images/adenomyose.jpg'),
  },
  {
    title: "L'opération - quand faut-il l'envisager ?",
    html: require('../blog/html/operation'),
    image: require('../blog/images/operation.jpg'),
  },
  {
    title: "Comment fonctionne l'ALD 31 ?",
    html: require('../blog/html/ald-31'),
    image: require('../blog/images/ald-31.jpg'),
  },
  {
    title: 'Le SOPKoi ?',
    html: require('../blog/html/sopkoi'),
    image: require('../blog/images/sopkoi.jpeg'),
  },
  {
    title: 'Les principaux symptômes du SOPK',
    html: require('../blog/html/symptomes-sopk'),
    image: require('../blog/images/symptomes-sopk.jpeg'),
  },
  {
    title: 'C’est quoi le lien entre endométriose et fatigue chronique ?',
    html: require('../blog/html/fatigue-chronique'),
    image: require('../blog/images/fatigue-chronique.jpeg'),
  },
  {
    title: 'Est-ce que la Ménopause mettra fin à mon Endométriose ?',
    html: require('../blog/html/menopause-endo'),
    image: require('../blog/images/menopause-endo.jpeg'),
  },
  {
    title: 'Pourquoi devrais-je participer à un programme d’Education Thérapeutique du Patient (ETP) ?',
    html: require('../blog/html/etp'),
    image: require('../blog/images/etp.jpeg'),
  },
  {
    title: 'Quelles sont les étapes du diagnostic du SOPK ?',
    html: require('../blog/html/diagnostic-sopk'),
    image: require('../blog/images/diagnostic-sopk.jpeg'),
  },
  {
    title: 'Je n’ose pas parler de mes dyspareunies avec mon partenaire, comment faire ?',
    html: require('../blog/html/dyspareunies'),
    image: require('../blog/images/dyspareunies.jpeg'),
  },
  {
    title: 'L’échographie pelvienne dans le diagnostic de l’endométriose',
    html: require('../blog/html/echopelvienne'),
    image: require('../blog/images/echopelvienne.jpeg'),
  },
  {
    title: "Quelles sont les thérapies complémentaires qui peuvent m'aider à soulager mon endométriose? 🌿💆‍♀️",
    html: require('../blog/html/therapy'),
    image: require('../blog/images/therapy.webp'),
  },
  {
    title: "Qu'est-ce que la RQTH et comment la demander pour un cas d'endométriose? ♿️💼",
    html: require('../blog/html/rqth'),
    image: require('../blog/images/rqth.webp'),
  },
  {
    title: "Ma conjointe souffre d'endométriose, comment puis-je l'accompagner dans son parcours de soins?👭💕👫",
    html: require('../blog/html/endo'),
    image: require('../blog/images/endo.webp'),
  },
  {
    title: 'Je viens d’être diagnostiquée, quelles sont mes prochaines étapes?🫂🏋️‍♀️',
    html: require('../blog/html/diagnostic'),
    image: require('../blog/images/diagnostic.webp'),
  },
  {
    title: 'Je pense être atteinte d’endométriose, quelle est la marche à suivre? 🤍💬',
    html: require('../blog/html/demarche'),
    image: require('../blog/images/demarche.webp'),
  },
  {
    title: 'Qu’est-ce que c’est l’endométriose? 🩺💬',
    html: require('../blog/html/endo-desc'),
    image: require('../blog/images/endo-desc.webp'),
  },
  {
    title: 'Pourquoi parle-t-on d’endométriose en mars? 🎗️ 💪',
    html: require('../blog/html/endo-mars'),
    image: require('../blog/images/endo-mars.webp'),
  },
];

const useStyles = createUseStyles({
  pageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  container: {
    padding: '8px 8px 0px',
    backgroundColor: AppColors.backgroundGrey,
    height: '100%',
    overflowY: 'scroll',
  },
  list: {
    width: '100%',
  },
  card: {
    overflow: 'hidden',
  },
  explainCard: {
    backgroundColor: AppColors.backgroundLightGreen,
    width: '100%',
    whiteSpace: 'pre-line',
  },
  title: {
    '& .ant-card-meta-title': {
      whiteSpace: 'pre-wrap',
    },
  },
  htmlContainer: {
    width: '100',
  },
});

export default function InfoPage() {
  // Hooks
  const { t } = useTranslation();
  const styles = useStyles();
  const { isSmallScreen } = useIsSmallScreen();
  const ref = useRef(null);

  // State
  const [postIndex, setPostIndex] = useState<number | undefined>(undefined);
  const [postPageScrollPosition, setPostPageScrollPosition] = useState<number>(0);
  const [applyPostPageScrollPosition, setApplyPostPageScrollPosition] = useState<number>(0);

  // eslint-disable-next-line prettier/prettier
  useEffect(() => {
    ScrollUtil.scrollTo(ref.current, applyPostPageScrollPosition);
  }, [applyPostPageScrollPosition]);

  function scrollToTop() {
    ScrollUtil.scrollToTop(ref.current);
  }

  function back() {
    setPostIndex(undefined);
    setApplyPostPageScrollPosition(postPageScrollPosition);
  }

  function selectPost(index: number) {
    setPostIndex(index);
    setPostPageScrollPosition(ScrollUtil.getScrollPosition(ref.current));
    scrollToTop();
  }

  return (
    <div className={styles.pageContainer}>
      <PageTopBar
        title={t('menu.info')}
        backButton={typeof postIndex === 'number'}
        onBackButtonClicked={() => back()}
      />
      <div className={styles.container} ref={ref}>
        {typeof postIndex === 'number' ? (
          <Row className="">
            <span dangerouslySetInnerHTML={{ __html: data[postIndex].html }} className={styles.htmlContainer} />
          </Row>
        ) : (
          <Row>
            <Card className={styles.explainCard}>
              <Meta avatar={<Avatar src={require('../assets/logo.png')} />} description={t('infoPage.description')} />
            </Card>
            <Divider />
            <List
              dataSource={data}
              bordered={false}
              grid={{ gutter: 8, column: isSmallScreen ? 1 : 4 }}
              className={styles.list}
              renderItem={(post, index) => {
                return (
                  <List.Item onClick={() => selectPost(index)}>
                    <Card className={styles.card} hoverable cover={<img alt="example" src={post.image} />}>
                      <Meta title={post.title} className={styles.title} />
                    </Card>
                  </List.Item>
                );
              }}
            />
          </Row>
        )}
      </div>
    </div>
  );
}
